<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Color <br />Utilities</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Design system includes an extended palette with primary colors and
              tint variations.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container w1200">
            <section>
              <div class="content-band" id="flex-containers">
                <h2>Color Utility Syntax</h2>
                <p class="mb-30">
                  Colors are defined using literal color names and a numeric
                  scale (where 100 is light and 800 is the primary)
                </p>

                <div class="gray-800 p-20 font-white code max-w800">&#x3C;<span class="code-purple">div </span>
                  <span class="code-blue">class</span>="<span class="code-green">container red-600</span>
                  "&#x3E;&#x3C;/<span class="code-purple">div</span>&#x3E;
                </div>

                <!-- Gray -->

                <div class="container flex flex-col max-w800 mt-30 pb-30">
                  <h3 class="container pb-10">
                    Gray
                    <!-- <br><span class="font-16">.gray-###</span>-->
                  </h3>
                  <div class="container flex-col">
                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div
                          class="rounded-4 p-10 ptb-20 gray-100 border"
                        ></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-100</span> <span>#F6F6F6</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-200"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-200</span> <span>#F2F2F2</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-300"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-300</span> <span>#E8E8E8</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-400"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-400</span> <span>#D9D9D9</span>
                        </div>
                      </div>
                    </div>

                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-500"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-500</span> <span>#BFBFBF</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-600"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-600</span> <span>#8C8C8C</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-700"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-700</span> <span>#434343</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gray-800"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gray-800</span> <span>#2C2C2C</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Red -->

                <div
                  class="container flex flex-col max-w800 mt-30 pb-30"
                >
                  <h3 class="container pb-10">Ohio brand red</h3>
                  <div class="container flex-col">
                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-100"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-100</span> <span>#FDE7E7</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-200"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-200</span> <span>#FBC4C5</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-300"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-300</span> <span>#F9ACAE</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-400"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-400</span> <span>#F7888B</span>
                        </div>
                      </div>
                    </div>

                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-500"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-500</span> <span>#F57174</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-600"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-600</span> <span>#F4595C</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-700"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-700</span> <span>#F1353A</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 red-800"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.red-800</span> <span>#EA1014</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Maroon Red -->

                <div class="container flex flex-col max-w800 mt-30 pb-30">
                  <h3 class="container pb-10">Ohio brand maroon</h3>
                  <div class="container flex-col">
                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-100 border"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-100</span> <span>#FAF6F6</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-200"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-200</span> <span>#F5EEEE</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-300"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-300</span> <span>#E9D9DC</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-400"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-400</span> <span>#BF95A5</span>
                        </div>
                      </div>
                    </div>

                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-500"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-500</span> <span>#AA6D7E</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-600"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-600</span> <span>#99495A</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-700"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-700</span> <span>#8B3043</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 maroon-800"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.maroon-800</span> <span>#700017</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Blue -->

                <div class="container flex flex-col max-w800 mt-30 pb-30">
                  <h3 class="container pb-10">Ohio brand blue</h3>
                  <div class="container flex-col">
                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div
                          class="rounded-4 p-10 ptb-20 blue-100 border"
                        ></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-100</span> <span>#F4F7FA</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-200"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-200</span> <span>#E2EBF2</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-300"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-300</span> <span>#C4D7E5</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-400"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-400</span> <span>#9EBCD4</span>
                        </div>
                      </div>
                    </div>

                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-500"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-500</span> <span>#749FC1</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-600"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-600</span> <span>#5F91B7</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-700"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-700</span> <span>#4781AD</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 blue-800"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.blue-800</span> <span>#3D7AA9</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Gold -->

                <div class="container flex flex-col max-w800 mt-30 pb-30">
                  <h3 class="container pb-10">Ohio brand gold</h3>
                  <div class="container flex-col">
                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-100"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-100</span> <span>#F4F8FB</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-200"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-200</span> <span>#EAF2F7</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-300"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-300</span> <span>#DCE8F2</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-400"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-400</span> <span>#CEE0ED</span>
                        </div>
                      </div>
                    </div>

                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-500"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-500</span> <span>#C0D6E8</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-600"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-600</span> <span>#A4C4DE</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-700"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-700</span> <span>#88B2D4</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 gold-800"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.gold-800</span> <span>#73A5CC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Green -->

                <div class="container flex flex-col max-w800 mt-30 pb-30">
                  <h3 class="container pb-10">Ohio brand green</h3>
                  <div class="container flex-col">
                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-100"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-100</span> <span>#F4F8FB</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-200"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-200</span> <span>#EAF2F7</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-300"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-300</span> <span>#DCE8F2</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-400"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-400</span> <span>#CEE0ED</span>
                        </div>
                      </div>
                    </div>

                    <div class="container ptb-5 even-cols gap-20">
                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-500"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-500</span> <span>#C0D6E8</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-600"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-600</span> <span>#A4C4DE</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-700"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-700</span> <span>#88B2D4</span>
                        </div>
                      </div>

                      <div>
                        <div class="rounded-4 p-10 ptb-20 green-800"></div>
                        <div class="font-15 flex justify-between pt-5">
                          <span>.green-800</span> <span>#73A5CC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <StickyTOC />
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Colors",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>

<style></style>
